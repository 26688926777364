<app-spinner *ngIf="spin"></app-spinner>
<app-ngx-table 
  type={{type}}
  description="A list of robots registered to the current user" 
  [tableData]="tableData" 
  [buttonEnabled]="true"
  *ngIf="!spin" 
  (clickedButton)="clickedButtonTable($event)" 
  (createNew)="newCreation()"
  (refreshTable)="refreshTableData()"></app-ngx-table>
