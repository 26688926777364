import { Asset } from './asset';

export class Robot extends Asset {
  public id: string;
  public owner: string;
  public name: string;
  public family: string;
  public family_variant?: string;
  public is_refurbished?: boolean;
  public is_smartcare?: boolean;
  public series: string;
  public sku: string;
  public serial_history =[];
  public serial_number?: string;
  public registration_date: any;
  public timezone: string;

  constructor() {
    super();
  }

  public get label() {
    return this.id + ' - ' + this.name;
  }

  public static copy(sourceRobot: Robot) {
    const targetRobot = new Robot();
    Object.assign(targetRobot, sourceRobot);

    return targetRobot;
  }
}
